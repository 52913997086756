import React, { useState, useEffect, useContext } from "react";
import Router from "next/router";
import Head from "next/head";
import Link from "next/link";
import Image from "next/image";
import { getPerformance } from "firebase/performance";
import app from "../config/firebaseApp";
import logoDesktop from "../public/assets/images/logo.png";
import logoResponsive from "../public/assets/images/cemtrik-logo-email.png";
import Layout, { siteTitle } from "../components/Layout";
import { LanguageContext } from "../context/LanguageContext";
import "../config/firebaseApp";

function Home() {
    const { language } = useContext(LanguageContext);

    const [datos, setDatos] = useState({
        codigo: "",
    });

    useEffect(() => {
        getPerformance(app);
    }, []);

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const register = (event) => {
        event.preventDefault();
        return Router.push(`/formulario/${datos.codigo}`);
    };

    return (
        <Layout>
            <Head>
                <title>{siteTitle}</title>
            </Head>
            <div className="py-14 sm:px-6 lg:px-8 h-screen bg-default-6">
                <div className="mt-20 md:mt-60 lg:mt-20 sm:mx-auto w-full md:w-680 grid grid-cols-1 md:grid-cols-2 px-5">
                    <div className="bg-white py-12 px-4 shadow rounded-2xl md:rounded-none md:rounded-l-lg sm:px-10">
                        <div className="block md:hidden">
                            <Link href="/" title="Forms - Cemtrik" passHref>
                                <div className="my-6 block text-center">
                                    <Image
                                        src={logoResponsive}
                                        width={248}
                                        height={56}
                                        alt="Forms - Cemtrik"
                                        layout="responsive"
                                    />
                                </div>
                            </Link>
                        </div>
                        <h1 className="font-sans font-normal text-xl text-center md:text-left">
                            {language.INDEX_FORM_TITLE}
                        </h1>
                        <form
                            className="mt-8 space-y-6"
                            action="#"
                            method="POST"
                            onSubmit={register}
                        >
                            <div>
                                <div>
                                    <input
                                        id="codigo"
                                        name="codigo"
                                        type="text"
                                        maxLength={6}
                                        required
                                        className="appearance-none relative block w-full px-3 py-2 leading-tight border-0 border-b-2 border-default-4 focus:ring-0 focus:border-default-2 placeholder-default-3 text-default-1 focus:z-10 sm:text-sm uppercase placeholder:normal-case"
                                        placeholder={language.PLACEHOLDER_CODE_FIELD}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <p className="text-default-2 text-xs italic mt-2">
                                    {language.LABEL_CODE_FIELD}
                                </p>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-2 hover:bg-primary-1 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-1"
                                >
                                    {language.INDEX_FORM_SUBMIT_TEXT_BUTTON}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="bg-primary-2 py-8 px-4 shadow sm:rounded-r-lg sm:px-10 invisible md:visible">
                        <Link href="/" title="Forms - Cemtrik" passHref>
                            <div className="mt-80 block">
                                <Image
                                    src={logoDesktop}
                                    width={248}
                                    height={56}
                                    alt="Forms - Cemtrik"
                                    layout="responsive"
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Home;
